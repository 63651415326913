const deepClone = <T>(object: T): T => JSON.parse(JSON.stringify(object))

/**
* Supports values of type: object, array, string, number, boolean
* @returns value or undefined
 */
const deepFind = (object: object, search: string, caseInsensitive?: boolean) => {
  return Object.values(object).find((value) => {
    if (typeof value === 'object' && value !== null) {
      return deepFind(value, search, caseInsensitive)
    }

    if (value !== null && value !== undefined) {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(search.toLowerCase()) ? value : null
      }
      else {
        return value === search ? value : null
      }
    }

    return null
  })
}

const filterEmptyStringValues = (object: Object) => Object.fromEntries(Object.entries(object).filter(([ key, value ]) => value !== ''))

const filterNullishValues = (object: Object) => Object.fromEntries(Object.entries(object).filter(([ key, value ]) => value !== undefined && value !== null))

export default {
  deepClone,
  deepFind,
  filterEmptyStringValues,
  filterNullishValues,
}